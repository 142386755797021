@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@opensea/ui-kit/styles/globals.css";
@import "./nprogress.css";

::selection {
  background-color: rgb(var(--color-blue-1));
  color: rgb(var(--color-white));
}

*:focus:not(:focus-visible) {
  outline: none;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

ul {
  padding-left: 0;
  list-style-type: none;
}

li {
  list-style-type: none;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit; /*  Correct color not being inherited.  Known issue: affects color of disabled elements. */
  font: inherit; /* Correct font properties not being inherited. */
  margin: 0; /* Address margins set differently in Firefox 4+, Safari, and Chrome. */
}

/**
  * Remove inner padding and search cancel button in Safari and Chrome on OS X.
  * Safari (but not Chrome) clips the cancel button when the search input has
  * padding (and textfield appearance).
  */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
  * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native "audio"
  *    and "video" controls.
  * 2. Correct inability to style clickable "input" types in iOS.
  * 3. Improve usability and consistency of cursor style between image-type
  *    "input" and others.
  */
button,
  html input[type="button"], /* 1 */
  input[type="reset"],
  input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

/**
  * Re-set default cursor for disabled elements.
  */
button[disabled],
html input[disabled] {
  cursor: default;
}

input::placeholder,
textarea::placeholder {
  color: rgb(var(--color-text-secondary));
}

:root {
  --nav-height: 72px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Only show scrollbar on hover, without shifting content */
.hover-scrollbar:not(:hover) {
  scrollbar-color: transparent transparent;
}
.hover-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.38);
}
.hover-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.hover-scrollbar::-webkit-scrollbar {
  background: transparent;
  height: 8px;
  width: 8px;
}

/* For views where we want to disable responsiveness below 1024px*/
html.non-responsive,
html.non-responsive body {
  min-width: 1024px;
}
